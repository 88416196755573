export default {
    state: {
        parameters: [],
    },
    mutations: {
        setParameters(state, payload) {
            payload.forEach(element => {
                try {
                    element.parameters = JSON.parse(element.parameters)

                } catch (error) {
                    element.parameters = {}
                }
            });
            state.parameters = payload
        },
    },
    actions: {
        setParameters(context, payload) {
            context.commit('setParameters', payload)
        },
    },
    getters: {
        getParameters(state) {
            return state.parameters
        },
        getParametersByRoute: (state) => (route) => {
            let pr = state.parameters.find(g =>
                g.to == route
            )
            return pr ? pr.parameters : null
        },
        getParameterBoolByRoute: (state) => (route, object, parameter, defaulValue) => {
            let pr = state.parameters.find(g =>
                g.to == route
            )
            if (pr.parameters[object]) {
                if (parameter && pr.parameters[object][parameter]) {
                    return pr.parameters[object][parameter];
                } else {
                    return false;
                }
            }
            if (defaulValue != undefined) {
                return defaulValue
            } else {
                return true;
            }
        },
        getParameterStringByRoute: (state) => (route, object, parameter, defaulValue) => {
            let pr = state.parameters.find(g =>
                g.to == route
            )
            if (pr.parameters[object]) {
                if (parameter && pr.parameters[object][parameter]) {
                    return pr.parameters[object][parameter];
                } else {
                    return "";
                }
            }
            if (defaulValue != undefined) {
                return defaulValue
            } else {
                return "";
            }
        },
        getParameterObjetcByRoute: (state) => (route, object, parameter, defaulValue) => {
            let pr = state.parameters.find(g =>
                g.to == route
            )
            if (pr.parameters[object]) {
                if (parameter && pr.parameters[object][parameter]) {
                    return pr.parameters[object][parameter];
                } else {
                    return {};
                }
            }
            if (defaulValue != undefined) {
                return defaulValue
            } else {
                return {};
            }
        },
        getParameterArrayByRoute: (state) => (route, object, parameter, defaulValue) => {
            let pr = state.parameters.find(g =>
                g.to == route
            )
            if (pr.parameters[object]) {
                if (parameter && pr.parameters[object][parameter]) {
                    return pr.parameters[object][parameter];
                } else {
                    return [];
                }
            }
            if (defaulValue != undefined) {
                return defaulValue
            } else {
                return [];
            }
        },
        getParameterByRoute: (state) => (route, object, parameter, defaulValue) => {
            let pr = state.parameters.find(g =>
                g.to == route
            )
            if (pr.parameters[object] != undefined) {
                if (parameter && pr.parameters[object][parameter] != undefined) {
                    return pr.parameters[object][parameter];
                } else {
                    return defaulValue;
                }
            } else {
                return defaulValue
            }
        },
    },
}